

.logo-image {
  width: 200px; /* Default width for mobile */
  height: auto; /* Maintain aspect ratio */
}

/* Media query for larger screens */
@media (min-width: 768px) { /* Adjust 768px based on when you want the desktop styling to apply */
  .logo-image {
    width: 280px; /* Larger width for desktop */
    /* height will automatically adjust due to 'height: auto' */
  }
}

.navbar-toggler-icon {
  background-size: 20px 20px; /* Adjust the size as needed */
}

@media (min-width: 992px) { /* Bootstrap's LG breakpoint */
  .navbar-nav .nav-item.dropdown:hover .dropdown-menu {
    display: block;
  }
}

/* Custom styling for the mobile accordion with 'accordion-flush' */
.navbar-dark .accordion-flush .accordion-item {
  background-color: #212529; /* bg-dark color */
}

.navbar-dark .accordion-flush .accordion-button {
  background-color: #212529; /* bg-dark color */
  color: #9b9d9e; /* Gray text color */
  padding-left: 0; /* Align with other navbar items */
  padding-top: 0.5rem; /* Reduced top padding */
  padding-bottom: 0.5rem; /* Reduced bottom padding */
}

.navbar-dark .accordion-flush .accordion-button.collapsed {
  color: #9b9d9e; /* Gray text color for collapsed state */
}

.navbar-dark .accordion-flush .accordion-body {
  background-color: #212529; /* bg-dark color */
  color: #9b9d9e; /* Gray text color */
}

/* Style adjustments for links inside the accordion body */
.navbar-dark .accordion-flush .accordion-body .nav-link {
  color: #9b9d9e; /* Gray text color */
}